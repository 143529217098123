import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import Loader from 'ToolboxComponents/commons/loader/loader';
import React, { useContext, useEffect, useState  } from 'react';
import { AccountContext, LanguageContext, WhiteLabelContext, GeolocationContext } from "ToolboxUtils/web/context/context";
import ClusterView from 'ToolboxComponents/webapp/pages/cluster-view/cluster-view';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import { Helmet } from "react-helmet";
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';
import slugify from 'url-slug';


const Heartstoppers = props => {
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');


  const theme = useApiChuchoteurs.get('/products', {
    params: {
      filter: 'heartstopper-theme',
      parentWhiteLabelId: whiteLabel.id,
      desireId: props.match.params.id,
      language
    }
  });

  const products = useApiChuchoteurs.get(`/products`, {
    params: { 
      filter: 'heartstoppers', 
      language: language, 
      parentWhiteLabelId: whiteLabel.id,
      whiteLabelId: whiteLabel.privatePartnerId,
      desireId: props.match.params.id,
      isInZone: whiteLabel.isInZone,
      latitude: whiteLabel.isInZone ? coordinates.latitude : whiteLabel.latitude,
      longitude: whiteLabel.isInZone ? coordinates.longitude : whiteLabel.longitude,
    }
  });

  useIsContentLoaded(products.isComplete);


  return (
     <>
     {products.isComplete  && theme.isComplete
        ? 
        <>
        <Helmet>
          <title>{theme.data.title} - {whiteLabel.name}</title>
          <meta name="description" content={getMetaDescription(theme.data.teaser)}/>
          <link rel="canonical" href={'https://' + window.location.hostname + (whiteLabel.path ? '/' + whiteLabel.path : '') + props.page.path[language] + `/${slugify(theme.data.title, {lower: true})}`} />
        </Helmet>
        <ClusterView
            cluster= {theme.data}
            products={products.data}
            addFavorite={null}
            deleteFavorite={null}
            hasNoDescription={true}
          />
          </>
        : <Loader />
      }
    </>
     
     
  )

    
     
}
  


export default Heartstoppers;
